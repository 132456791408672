<script>
import { authMethods } from '@state/helpers'
import appConfig from '@src/app.config'

export default {
  page: {
    title: 'Log in',
    meta: [{ name: 'description', content: `Log in to ${appConfig.title}` }],
  },
  data() {
    return {
      username: '',
      password: '',
      authError: null,
      tryingToLogIn: false,
    }
  },
  computed: {
    placeholders() {
      return process.env.NODE_ENV === 'production'
        ? {}
        : {
            username: 'Use "admin" to log in with the mock API',
            password: 'Use "password" to log in with the mock API',
          }
    },
  },
  methods: {
    ...authMethods,
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.tryingToLogIn = true
      // Reset the authError if it existed.
      this.authError = null
      return this.logIn({
        username: this.username,
        password: this.password,
      })
        .then((token) => {
          this.tryingToLogIn = false

          // Redirect to the originally requested page, or to the home page
          this.$router.push(this.$route.query.redirectFrom || { name: 'home' })
        })
        .catch((error) => {
          this.tryingToLogIn = false
          this.authError = error
        })
    },
  },
}
</script>

<template>
  <b-container
    ><b-row align-v="stretch"
      ><b-col></b-col
      ><b-col cols="8" align-self="center">
        <form :class="$style.form" @submit.prevent="tryToLogIn">
          <img id="logo" src="@assets/images/logo.png" alt="Logo" />
          <BaseInputText
            v-model="username"
            name="username"
            :placeholder="placeholders.username"
          />
          <BaseInputText
            v-model="password"
            name="password"
            type="password"
            :placeholder="placeholders.password"
          />
          <BaseButton :disabled="tryingToLogIn" type="submit">
            <BaseIcon v-if="tryingToLogIn" name="sync" spin />
            <span v-else>
              Log in
            </span>
          </BaseButton>
          <p v-if="authError">
            There was an error logging in to your account.
          </p>
        </form> </b-col
      ><b-col></b-col></b-row
  ></b-container>
</template>

<style lang="scss" module>
@import '@design';

.form {
  margin-top: 6em;
  text-align: center;
}
</style>
